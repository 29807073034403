<template>
	<div>
		<PageTitle title="添加地址" fixed v-if="type != 1"></PageTitle>

		<div style="width: 85%;margin: 15px auto;">
			<List :width="100">
				<ListItem>
					<template #top>
						<van-form ref="addressForm" colon label-width="80px">
							<p>添加新地址</p>
							<van-field v-model="formData.addressee" label="收件人姓名" placeholder="请输入收件人姓名" :rules="[{ required: true }]" />
							<van-field v-model="formData.phone" label="联系号码" placeholder="请输入联系号码" :rules="[{ required: true }, { pattern, message: '请输入正确的联系号码' }]" />

							<van-field
								v-model="areaText"
								label="所在地区"
								@click="areaShow = true"
								type="textarea"
								right-icon="arrow-down"
								placeholder="请选择所在地区"
								autosize
								readonly
								:rules="[{ required: true }]"
							/>
							<van-action-sheet v-model="areaShow" :closeable="false">
								<van-area title="选择地区" :area-list="areaData" value="330304" @confirm="areaConfirm" @cancel="areaShow = false" />
							</van-action-sheet>
							<van-action-sheet title="选择街道" v-model="streetShow" :actions="streets" @cancel="streetShow = false" @select="streetConfirm" />

							<van-field v-model="formData.specificAddress" label="详细地址" placeholder="请输入详细地址" type="textarea" right-icon="edit" :rules="[{ required: true }]" />
							<!--<van-field-->
							<!--v-model="formData.specificAddress"-->
							<!--label="智能填写"-->
							<!--placeholder="粘贴地址信息，自动填写"-->
							<!--type="textarea"-->
							<!--right-icon="edit"/>-->
						</van-form>
					</template>
				</ListItem>
			</List>
			<van-button type="primary" block @click="submit()">保存</van-button>
			<template v-if="type == 1">
				<slot name="cancelBtn"></slot>
			</template>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import PageTitle from '@/components/PageTitle.vue';
import List from '@/components/home-page/List.vue';
import ListItem from '@/components/home-page/ListItem.vue';

import AreaData from '@/api/area.js';
import { getAddressStreet, insertUserAddress, updateUserAddress, getAddressInfo } from '@/api/user.js'; //, updateUserAddress

export default {
	components: {
		PageTitle,
		List,
		ListItem
	},
	name: 'AddAddress',
	props: ['type'],
	data() {
		return {
			formData: {
				id: this.$route.params.addressId,
				userid: this.$store.getters.userInfo.UserId,
				patientid: '',
				patientname: '',
				addressee: '',
				phone: '',
				province: '',
				city: '',
				area: '',
				street: '',
				specificAddress: ''
			},
			pattern: /^((\d{11})|(\d{7,8})|(\d{4}|\d{3})-(\d{7,8}))$/,
			jzxh: this.$route.params.jzxh,
			areaText: '',
			areaData: AreaData,
			areaShow: false,
			streetShow: false,
			streets: []
		};
	},
	methods: {
		areaConfirm(value) {
			this.$set(this.formData, 'province', value[0].name);
			this.$set(this.formData, 'city', value[1].name);
			this.$set(this.formData, 'area', value[2].name);
			getAddressStreet(value[2].code).then(
				result => {
					if (result) {
						this.streets = result.map(function(currentValue, index) {
							return { code: index, name: currentValue };
						});
						this.streets.push({ code: '999999', name: '暂不选择' });
					} else {
						this.streets = [{ code: '999999', name: '暂不选择' }];
					}

					this.streetShow = true;
					this.areaShow = false;
				},
				() => {}
			);
		},
		streetConfirm(value) {
			this.$set(this.formData, 'street', value.name);
			this.areaText = this.formData.province + '\n' + this.formData.city + '\n' + this.formData.area + '\n' + this.formData.street;
			this.streetShow = false;
		},
		submit() {
			if (this.$props.type == 1) {
				this.$refs.addressForm.validate().then(() => {
					insertUserAddress(this.formData).then(
						() => {
							this.$emit('getMsg', '新增成功');
						},
						() => {
							this.$emit('getMsg', '新增失败');
						}
					);
				});
			} else {
				if (this.$route.params.addressId != '' && this.$route.params.addressId != null) {
					this.$refs.addressForm.validate().then(
						() => {
							//提交表单
							if (this.$route.params.addressId == '0') {
								insertUserAddress(this.formData).then(
									() => {
										this.$router.push('/address/' + this.$route.params.departmentId + '/' + this.$route.params.doctorId);
									},
									() => {}
								);
							} else {
								updateUserAddress(this.formData).then(
									() => {
										this.$router.push('/address/' + this.$route.params.departmentId + '/' + this.$route.params.doctorId);
									},
									() => {}
								);
							}
						},
						() => {}
					);
				} else {
					this.$router.push(
						'/encore/' +
							this.jzxh +
							'/' +
							this.formData.addressee +
							'/' +
							this.formData.province +
							'/' +
							this.formData.city +
							'/' +
							this.formData.area +
							'/' +
							this.formData.street +
							'/' +
							this.formData.specificAddress +
							'/' +
							this.formData.phone
					);
				}
			}
		}
	},
	created() {
		if (this.$props.type != 1) {
			if (this.$route.params.addressId != '' && this.$route.params.addressId != null) {
				if (this.$route.params.addressId != '0') {
					getAddressInfo(this.$route.params.addressId).then(
						result => {
							this.formData = result;
							this.areaText = this.formData.province + '\n' + this.formData.city + '\n' + this.formData.area + '\n' + this.formData.street;
						},
						() => {}
					);
					return;
				}
				this.areaText = this.formData.province + '\n' + this.formData.city + '\n' + this.formData.area + '\n' + this.formData.street;
			} else {
				this.formData = {
					addressee: this.$route.params.xm,
					phone: this.$route.params.phone,
					province: this.$route.params.dz1,
					city: this.$route.params.dz2,
					area: this.$route.params.dz3,
					street: this.$route.params.dz4,
					specificAddress: this.$route.params.dz5
				};
				this.areaText = this.formData.province + '\n' + this.formData.city + '\n' + this.formData.area + '\n' + this.formData.street;
			}
		}
	}
};
</script>

<style scoped lang="less">
@import '../style/var.less';

.infoContainer {
	padding: 10px 0;

	.top {
		p {
			text-align: center;
			margin: 0;
		}
	}
}
</style>
